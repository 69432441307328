import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {MetaService} from "@core/services/meta.service";
import {I18nService} from "@core/services/i18n/i18n.service";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private router: Router,
              private meta: MetaService,
              private i18n: I18nService) {
  }

  getTitleFromRoute(): string {
    const url = this.router.url;
    return this.convertUrl(url);
  }

  private convertUrl(url: string) {
    return url
      .split('/')
      .filter(Boolean)
      .map((segment: string) => this.i18n.translatePhrase('ROUTER.' + (segment.toLowerCase())))
      .join(' | ');
  }

  setPageTitle() {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationStart) {
        let title: string = '';
        if (res.url === '/') {
          title = this.i18n.translatePhrase('ROUTER.HOME')
        } else {
          title = this.convertUrl(res.url)
        }
        this.meta.setTitle(title);
      }
    })
  }

  markAllAsDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key)?.markAsDirty();
    });
  }

  getTooltip(actionKey: string, tooltip: string): string {
    return this.i18n.translatePhrase('ACTIONS.' + actionKey) + ' ' + tooltip;
  }
}
