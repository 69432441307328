import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(private translateService: TranslateService) {}

  setUpLanguages() {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang('en');
  }

  translatePhrase(phrase: string): string {
    return this.translateService.instant(phrase);
  }

  switchLanguages(lang: string) {
    this.translateService.use(lang);
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  onLangChange(){
    return this.translateService.onLangChange;
  }
}
